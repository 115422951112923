import React from 'react';
import './HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <div className="content">
        <h1 className="heading">Добро пожаловать!</h1>
        <p className="paragraph">Это платформа для обмена студенческой информацией.</p>
        <p className="with-love">with love, OND</p>
      </div>
    </div>
  );
}

export default HomePage;
